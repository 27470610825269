import React from "react";
import { graphql } from "gatsby";
import { isPast } from "date-fns";

import Layout from "../components/layout";
import SecondaryHero from "../components/SecondaryHero";
import SecondaryMenu from "../components/SecondaryMenu";
import SEO from "../components/seo";
import EventsList from "../components/EventsList";

const Events = ({ data: { menuItems, settings, eventsPage, events } }) => {
	const allEvents = events.nodes.filter(
		(event) => !isPast(new Date(event.date))
	);

	const menu = menuItems.nodes;
	const allSettings = settings.nodes[0];
	const logoImg = allSettings.logo;
	const contactNum = allSettings.contactNumber;
	const { preTitleTitle, heroTitle, heroSubtitle } = eventsPage.nodes[0];
	const hasEvents = allEvents.length > 0;
	return (
		<Layout>
			<SEO title="Upcoming Events" description={heroSubtitle} />
			<header>
				<SecondaryMenu
					menuItems={menu}
					logo={logoImg}
					contactNumber={contactNum}
				/>
				<div className={hasEvents ? `pb-32` : ''}>
					<SecondaryHero
						preTitle={preTitleTitle}
						heroTitle={heroTitle}
						heroSubtitle={heroSubtitle}
					/>
				</div>
			</header>
			<main id="main" tabindex="-1">
				{hasEvents && (
					<div className="relative bg-gray-100 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
						<div className="relative max-w-7xl mx-auto">
							<EventsList events={allEvents} />
						</div>
					</div>
				)}
				{!hasEvents && (
					<div className="h-[50vh] relative max-w-7xl mx-auto px-4 lg:px-8">
						<h2 className="mb-0">No events scheduled</h2>
						<p className="mt-2">Check back again soon!</p>
					</div>
				)}
			</main>
		</Layout>
	);
};

export const query = graphql`
	query ourEventsQuery {
		menuItems: allSanityMenuItems {
			nodes {
				menuItem
				menuLink
				sortOrder
			}
		}
		eventsPage: allSanityEventsPage {
			nodes {
				preTitleTitle
				heroTitle
				heroSubtitle
			}
		}
		settings: allSanitySettings {
			nodes {
				contactEmail
				logo {
					...Image
				}
			}
		}
		events: allSanityEvents(sort: { fields: date, order: ASC }, limit: 10) {
			nodes {
				description
				date
				cta {
					link
					text
				}
				cost
				name
				image {
					...Image
				}
			}
		}
	}
`;

export default Events;
