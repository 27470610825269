import React from "react";
import EventCard from "./EventCard";

const EventsList = ({ events }) => {
	const firstEvent = events[0];

	return (
		<div className="relative bg-gray-100 pt-16 pb-20 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
			<div className="relative max-w-7xl mx-auto">
				<div className="-mt-80 mb-24">
					<h2 className="text-base text-yellow-600 font-semibold tracking-wide uppercase">
						Next Event
					</h2>{" "}
					<EventCard isHero={true} {...firstEvent} />
				</div>
				{events.length > 1 && (
					<>
						<h2 className="text-base text-yellow-600 font-semibold tracking-wide uppercase">
							Upcoming Events
						</h2>{" "}
						<div className="mt-12 max-w-lg mx-auto grid gap-5 md:grid-cols-2 md:max-w-none xl:grid-cols-3 xl:max-w-none">
							{events.map((event, index) => {
								if (index === 0) {
									return null;
								}
								return <EventCard {...event} key={event.name} />;
							})}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default EventsList;
