import React, { useContext } from "react";
import Swal from 'sweetalert2';
import confetti from "canvas-confetti";
import Image from "gatsby-plugin-sanity-image";
import Button from "./Button";
import { formatRelative } from "date-fns";
import AUlocale from "date-fns/locale/en-AU";
import { GlobalContext } from "./layout";

let firingEasterEgg = false;

const fireEasterEgg = () => {
	if(firingEasterEgg){
		return;
	}

	firingEasterEgg = true;
	const duration = 5000;
	const animationEnd = Date.now() + duration;
	const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

	function randomInRange(min, max) {
		return Math.random() * (max - min) + min;
	}

	var interval = setInterval(function() {
	var timeLeft = animationEnd - Date.now();

		if (timeLeft <= 0) {
			Swal.fire({
				title: 'You found it!',
				text: 'Send us a message using the chat (or our contact form) with the secret code "GIMME $100", your name, and your email address to go in the draw to win the $100 website launch prize!',
				icon: 'success',
				confirmButtonText: 'Hooray!'
			})
			firingEasterEgg = false;
			return clearInterval(interval);
		}

		const particleCount = 50 * (timeLeft / duration);
		// since particles fall down, start a bit higher than random
		confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }));
		confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }));
	}, 250);
}

const EventCard = ({
	name,
	description,
	image,
	date,
	cta: { link, text },
	isHero = false,
}) => {
	const relativeDate = formatRelative(new Date(date), new Date(), {
		locale: AUlocale,
	});

	const { displayEasterEggs } = useContext(GlobalContext);

	if (isHero) {
		return (
			<>
				{displayEasterEggs && <div className="bg-yellow-500 w-5 h-5 rounded-full flex items-center justify-center absolute text-white animate-ping transform translate-x-2 translate-y-2 right-0 z-0" onClick={fireEasterEgg}></div>}
				<div className="w-full flex flex-col lg:flex-row-reverse lg:gap-16 bg-white rounded-lg shadow-lg lg:p-12 border border-gray-200 overflow-hidden relative">
					<div className="w-full lg:w-96">
						<Image
							{...image}
							width={600}
							className="w-full mb-0 lg:rounded-lg lg:shadow-lg"
							// className="h-48 lg:h-96 w-full object-cover mb-0 lg:rounded-lg lg:shadow-lg"
							alt={name}
						/>
					</div>
					<div className="flex-1 flex flex-col justify-between p-6 lg:p-0">
						<div className="flex-1">
							<p className="text-sm lg:text-base font-medium text-indigo-600 capital-first">
								{relativeDate}
							</p>
							<p className="mt-1 text-xl lg:text-4xl font-semibold lg:font-extrabold text-gray-900 sm:text-5xl">
								{name}
							</p>
							<p className="mt-3 text-base lg:text-lg text-gray-500">
								{description}
							</p>
						</div>
						<Button link={link} inline={true} isExternalLink={true}>
							{text}
						</Button>
					</div>
				</div>
			</>
		);
	}

	return (
		<div
			key={name}
			className="flex flex-col rounded-lg shadow-lg overflow-hidden"
		>
			<div className="flex-shrink-0">
				<Image
					{...image}
					width={600}
					// className="h-48 object-cover w-full mb-0"
					className="w-full mb-0"
					alt={name}
				/>
			</div>
			<div className="flex-1 bg-white p-6 flex flex-col justify-between">
				<div className="flex-1">
					<p className="text-sm font-medium text-indigo-600 capital-first">
						{relativeDate}
					</p>
					<div className="block mt-2">
						<p className="text-xl font-semibold text-gray-900">{name}</p>
						<p className="mt-3 text-base text-gray-500">{description}</p>
					</div>
				</div>
				<Button
					type="secondary"
					link={link}
					inline={true}
					isExternalLink={true}
				>
					{text}
				</Button>
			</div>
		</div>
	);
};

export default EventCard;
